import { onAuthStateChanged } from 'firebase/auth';
import './App.css';
import { auth } from './helpers/firebase';
import route from './helpers/route';
import { top } from '.';
import TopBar from './components/topBar';

function App() {
  const url = window.location.pathname.split("/")
  const path  = url[1]

  onAuthStateChanged(auth, (user) => {
    if (user === null){
      if(path !== "login" && path !== "register"){
        route("login") 
      }
    } else {
      // top.render(<TopBar/>)
      path !== "login" && path !== "register" ? route(path) : route("dashboard");  
    } 
})
}
export default App;
