import {OpenAIOutlined, CloudServerOutlined, OrderedListOutlined, DeploymentUnitOutlined, FolderOpenOutlined, DatabaseOutlined,LockOutlined, MailOutlined, JavaScriptOutlined, GlobalOutlined } from "@ant-design/icons"
const icons = {
    "ai": <OpenAIOutlined />,
    "infrastructure": <CloudServerOutlined />,
    "belt": <DeploymentUnitOutlined   />,
    "nodejs": <JavaScriptOutlined   />,
    "web": <GlobalOutlined   />,
    "email": <MailOutlined   />,
    "json": <DatabaseOutlined   />,
    "file": <FolderOpenOutlined   />,
    "list": <OrderedListOutlined />,
    "security": <LockOutlined />,


}

export default icons