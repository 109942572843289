// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, updateProfile } from "firebase/auth";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { fetchInstance, fetchResources } from "./requests";
import route from "./route";
import Skeleton from "../components/layout";
import { root } from "..";
function _0x4a03(){const _0x497943=['1:1084914130027:web:b5b56042e989897341652e','orioons.firebaseapp.com','3848305PKjWGp','1658664zegGXK','3439698ULGhYm','G-H0F2LEKP6L','1084914130027','226048NkscIw','388dyMbTs','721040boNIRD','7JETvcj','3921ChsXQW','331538Giyeak','orioons','orioons.appspot.com'];_0x4a03=function(){return _0x497943;};return _0x4a03();}const _0x58a500=_0xa280;function _0xa280(_0x2a96f1,_0x3a794a){const _0x4a035f=_0x4a03();return _0xa280=function(_0xa2808c,_0x571717){_0xa2808c=_0xa2808c-0xbf;let _0x4d9ddf=_0x4a035f[_0xa2808c];return _0x4d9ddf;},_0xa280(_0x2a96f1,_0x3a794a);}(function(_0x33762c,_0x5211b6){const _0x1f9e41=_0xa280,_0x1befa6=_0x33762c();while(!![]){try{const _0x5d79d2=parseInt(_0x1f9e41(0xc0))/0x1+parseInt(_0x1f9e41(0xc5))/0x2+parseInt(_0x1f9e41(0xc4))/0x3*(parseInt(_0x1f9e41(0xc1))/0x4)+parseInt(_0x1f9e41(0xca))/0x5+-parseInt(_0x1f9e41(0xcc))/0x6+parseInt(_0x1f9e41(0xc3))/0x7*(-parseInt(_0x1f9e41(0xc2))/0x8)+-parseInt(_0x1f9e41(0xcb))/0x9;if(_0x5d79d2===_0x5211b6)break;else _0x1befa6['push'](_0x1befa6['shift']());}catch(_0x1ac766){_0x1befa6['push'](_0x1befa6['shift']());}}}(_0x4a03,0x6b8e4));const firebaseConfig={'apiKey':'AIzaSyAEFyzF3nlf6l06hlfssXwml19PKSJ7_LQ','authDomain':_0x58a500(0xc9),'projectId':_0x58a500(0xc6),'storageBucket':_0x58a500(0xc7),'messagingSenderId':_0x58a500(0xbf),'appId':_0x58a500(0xc8),'measurementId':_0x58a500(0xcd)};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth();
const db = getFirestore();
export let user = {}

export let instanceData = window.sessionStorage.getItem("instanceData_x_01") ? JSON.parse(window.sessionStorage.getItem("instanceData_x_01")) : {
  core: 0,
  approval:"approved",
  storage: 0,
  memory: 0,
  host:"localhost",
  ip:"0.0.0.0",
  pricing: 0,
  limit: 0,
  plan: "-",
  location: "-",
  status:0
}
export let resourceData = window.sessionStorage.getItem("resourceData_x_01") ? JSON.parse(window.sessionStorage.getItem("resourceData_x_01")) : {
iaas:[], saas:[], paas:[], security:[], aiml:[], status:0
}


const firebaseAuthErrorMessages = {
  "auth/invalid-email": "The email address is invalid.",
  "auth/user-disabled": "The user account has been disabled.",
  "auth/user-not-found": "There is no user corresponding to the given email.",
  "auth/wrong-password": "The password is invalid.",
  "auth/email-already-in-use": "The email address is already in use by another account.",
  "auth/operation-not-allowed": "This operation is not allowed.",
  "auth/weak-password": "The password is too weak.",
  "auth/missing-android-pkg-name": "An Android package name must be provided if the Android app is required to be installed.",
  "auth/missing-continue-uri": "A continue URL must be provided in the request.",
  "auth/missing-ios-bundle-id": "An iOS bundle ID must be provided if an App Store ID is provided.",
  "auth/invalid-continue-uri": "The continue URL provided in the request is invalid.",
  "auth/unauthorized-continue-uri": "The domain of the continue URL is not whitelisted. Check the Firebase console to ensure that the domain is whitelisted.",
  "auth/invalid-dynamic-link-domain": "The provided dynamic link domain is not configured or authorized for the current project.",
  "auth/argument-error": "An internal error has occurred.",
  "auth/invalid-api-key": "Your API key is invalid.",
  "auth/app-deleted": "This instance of FirebaseApp has been deleted.",
  "auth/invalid-credential": "The supplied auth credential is malformed or has expired.",
  "auth/invalid-verification-code": "The verification code is invalid.",
  "auth/invalid-verification-id": "The verification ID is invalid.",
  "auth/user-mismatch": "The supplied credentials do not correspond to the previously signed in user.",
  "auth/requires-recent-login": "This operation is sensitive and requires recent authentication. Log in again before retrying this request.",
  "auth/account-exists-with-different-credential": "An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.",
  "auth/email-already-in-use": "The email address is already in use by another account.",
  "auth/network-request-failed": "A network error has occurred. Please try again later.",
  "auth/too-many-requests": "We have blocked all requests from this device due to unusual activity. Try again later.",
  "auth/operation-not-supported-in-this-environment": "This operation is not supported in the environment this application is running on. \"location.protocol\" must be http, https or chrome-extension and web storage must be enabled.",
  "auth/timeout": "The operation has timed out.",
  "auth/captcha-check-failed": "The reCAPTCHA response token provided is either invalid, expired, or already used.",
  "auth/web-storage-unsupported": "This browser is not supported or 3rd party cookies and data may be disabled."
};

onAuthStateChanged(auth, (u) => {
  if (u === null){
  } else {
   user = u
  } 
})



export const signIn = (email, password) =>{
  return new Promise ((resolve, reject)=>{
    signInWithEmailAndPassword(auth, email, password).then((e)=> {
      resolve(e)
    } ).catch((err) => {
      reject(firebaseAuthErrorMessages[err.code])
    });
  })
}
export const exportInstance = () =>{
  return new Promise ((resolve, reject)=>{

    instanceData.status !== 0  ?  resolve(instanceData) : fetchInstance(user.uid).then((e)=>{
      console.log(e)
      instanceData = {
            ...instanceData,
            ...e.data,
            status: 1
          };
          window.sessionStorage.setItem("instanceData_x_01", JSON.stringify(instanceData))
            resolve(instanceData)
          } ).catch((err) => {
              reject("Error fetching instance")
            });
   
  })
}
export const exportResource = () =>{
  return new Promise ((resolve, reject)=>{
    resourceData.status !== 0  ?  resolve(resourceData) :  fetchResources().then((e)=> {
      let p = {}
      p.aiml = e.data.aiml 
      p.iaas = e.data.iaas 
      p.saas = e.data.saas 
      p.paas = e.data.paas 
      p.security = e.data.security 
      resourceData = {
        ...resourceData,
        ...p,
        status: 1
      };
      window.sessionStorage.setItem("resourceData_x_01", JSON.stringify(resourceData))
      resolve(resourceData)
    } ).catch((err) => {
      reject("Error fetching resources")
    });
  })
}

export const forceReloadInstanceAndResources = () => {
  window.sessionStorage.setItem("resourceData_x_01", JSON.stringify({status:0}))
  window.sessionStorage.setItem("instanceData_x_01", JSON.stringify({status:0}))
  exportInstance().then(()=>{
    exportResource().then(()=>{
    window.location.reload()
    })
  })
  }