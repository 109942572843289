import React, { useState } from 'react';
import { Alert, Button, Card, Divider, Form, Image, Input, Select, Space } from 'antd';
import logo from "../media/orioons.png";
import logoDark from "../media/orioons_dark.png";
import { signIn } from '../helpers/firebase';


const LoginPage = () => {
 const [email, setEmail]  = useState("");
 const [password, setPassword] = useState("");
 const [mo, setMo] = useState("");
 const [user, setUser] = useState({uid: null, displayName: null, email: ""});
  return (

    <div className="w-full min-h-screen bg-gray-50 flex flex-col sm:justify-center items-center pt-6 sm:pt-0">
    <div className="w-full sm:max-w-md p-5 mx-auto">
    <Image preview={false} width={ window.innerHeight > window.innerWidth ? "50%" : "40%"} className='mb-6 md:mb-2' src={logoDark} draggable={false} />

      <form onSubmit={(e) =>{ e.preventDefault(); if(email &&  password){signIn(email, password).then((e)=>{setMo(""); setUser(e.user); setEmail("Signed In")}).catch((e)=>{setMo(<Alert message={e} type="error" />)});} else{setMo(<Alert message="Please fill up all the fields" type="error" />)} }}>
        
        <div className="mb-4">
          <label className="block mb-1" htmlFor="email">
            Email-Address
          </label>
          <input
          value={email}
          disabled={user.uid  !== null ? true : false} 
          onChange={(e) => setEmail (e.target.value)}
            id="email"
            type="text"
            name="email"
            className="py-2 px-3 border border-gray-300 focus:border-red-300 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-1" htmlFor="password">
            Password
          </label>
          <input
          disabled={user.uid  !== null ? true : false} 
          value={password}
          onChange={(e) => setPassword(e.target.value)}
            id="password"
            type="password"
            name="password"
            className="py-2 px-3 border border-gray-300 focus:border-red-300 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
          />
          <br/>
          {mo}
        </div>
        <div className="mt-6 flex items-center justify-between">
          <div className="flex items-center">
            <input
              id="remember_me"
              type="checkbox"
              className="border border-gray-300 text-red-600 shadow-sm focus:border-red-300 focus:ring focus:ring-red-200 focus:ring-opacity-50"
            />
            <label
              htmlFor="remember_me"
              className="ml-2 block text-sm leading-5 text-gray-900"
            >
              {" "}
              Remember me{" "}
            </label>
          </div>
          <a href="#" className="text-sm">
            {" "}
            Forgot your password?{" "}
          </a>
        </div>
        <div className="mt-6">
          <button className="w-full inline-flex items-center justify-center px-4 py-2 bg-[#343a40] border border-transparent rounded-md font-semibold capitalize text-white hover:bg-red-700 active:bg-[#343a40] focus:outline-none focus:border-red-700 focus:ring focus:ring-red-200 disabled:opacity-25 transition">
            Sign In
          </button>
        </div>
        <div className="mt-6 text-center">
          <a href="#" className="underline">
            Sign up for an account
          </a>
        </div>
      </form>
      <h2 className="mt-6 text-right text-2xl  font-bold">Welcome. </h2>
      <p className='text-right text-large  font-medium'>{user.displayName !== null ? user.displayName : user.email}</p>
    </div>
  </div>
  
  
  );
};
export default LoginPage;