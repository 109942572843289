import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { LoadingOutlined } from '@ant-design/icons';
import { Image, Spin } from 'antd';
import logo from "./media/orioons.png";
import { exportInstance, exportResource, resourceData } from './helpers/firebase';
import Footer from './components/footer';

export const top = ReactDOM.createRoot(document.getElementById('t'));
export const root = ReactDOM.createRoot(document.getElementById('r'));
export const footer = ReactDOM.createRoot(document.getElementById('f'));
exportResource()
root.render(<React.StrictMode><div className="App"> <header className="App-header">  <Spin indicator={<LoadingOutlined style={{fontSize: 24, color:"white"}} spin/> } />  <Image preview={false} width={"10%"} draggable={false} alt='Orioons Logo' src={logo}/> </header> </div></React.StrictMode>);
root.render(<React.StrictMode><App/></React.StrictMode>);

