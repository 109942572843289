import React, { useState } from 'react';
import { Card, List, Row, Col, Divider, Avatar, Button, Tag } from 'antd';
import Icon, { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import { resourceData } from '../helpers/firebase';
import icons from '../helpers/icons';
const { Meta } = Card;
const gridStyle = {
  width: '25%',
  textAlign: 'center',
};

const AllResources = () => {

  const [resources, setResources] = useState(resourceData)
  
  return(<>
  <h2 className='text-2xl m-2 font-bold'>IaaS - Infrastructure as a Service</h2>
  <Divider></Divider>
      <List
      grid={{
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 4,
        lg: 5,
        xl: 5,
        xxl: 5,
      }}
      dataSource={resources.iaas}
      renderItem={(item,index) => (
        <List.Item>
          <Card className='p-1' actions={[<Button key={"iaas"+index}> Deploy</Button>, <Button key={"iaas"+index}> Learn More</Button>]} title={item.name} hoverable extra={<Avatar className='bg-blue-500' size={"large"} icon={icons[item.icon]}  />} >
                <Meta description={item.description}  />
             
          </Card>
        </List.Item>
      )}
    />
   
   <h2 className='text-2xl m-2 font-bold'>SaaS - Software as a Service</h2>
  <Divider></Divider>
      <List
      grid={{
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 4,
        lg: 5,
        xl: 5,
        xxl: 5,
      }}
      dataSource={resources.saas}
      renderItem={(item,index) => (
        <List.Item>
          <Card className='p-1' actions={[<Button key={"iaas"+index}> Deploy</Button>, <Button key={"iaas"+index}> Learn More</Button>]} title={item.name} hoverable extra={<Avatar className='bg-blue-500' size={"large"} icon={icons[item.icon]}  />} >
                <Meta description={item.description}  />
            
          </Card>
        </List.Item>
      )}
    />

        

  
          <h2 className='text-2xl m-2 font-bold'>AIML - Artificaial Intelligence & Machine Learning</h2>
  <Divider></Divider>
      <List
      grid={{
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 4,
        lg: 5,
        xl: 5,
        xxl: 5,
      }}
      dataSource={resources.aiml}
      renderItem={(item,index) => (
        <List.Item>
          <Card className='p-1' actions={[<Button key={"iaas"+index}> Deploy</Button>, <Button key={"iaas"+index}> Learn More</Button>]} title={item.name} hoverable extra={<Avatar className='bg-blue-500' size={"large"} icon={icons[item.icon]}  />} >
                <Meta description={item.description}  />
           

          </Card>
        </List.Item>
      )}
    />


    
  
<h2 className='text-2xl m-2 font-bold'>Security</h2>
  <Divider></Divider>
      <List
      grid={{
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 4,
        lg: 5,
        xl: 5,
        xxl: 5,
      }}
      dataSource={resources.security}
      renderItem={(item,index) => (
        <List.Item>
          <Card className='p-1' actions={[<Button key={"iaas"+index}> Deploy</Button>, <Button key={"iaas"+index}> Learn More</Button>]} title={item.name} hoverable extra={<Avatar className='bg-blue-500' size={"large"} icon={icons[item.icon]}  />} >
                <Meta description={item.description}  />
          

          </Card>
        </List.Item>
      )}
    />

    </>)
}

export default AllResources;