import { Empty } from "antd";
import AllResources from "../components/allResources";
import Dashboard from "../pages/dashboard";
import MyResources from "../pages/myResources";

const pages  = {
    "belt":<Dashboard/>,
    "resources": <AllResources/>,
    "my": <MyResources/>,
    "free": <Empty />,
    "team": <Empty />,
    "support": <Empty />,
    "learn": <Empty />,
    "settings": <Empty />,
}

export default pages