import React, { useEffect, useState } from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LayoutOutlined ,
  DatabaseOutlined ,
  AppstoreOutlined ,
  TeamOutlined  ,
  UserOutlined,
  BookOutlined  ,
  SettingOutlined  ,
  ReloadOutlined
} from '@ant-design/icons';
import { Alert, Avatar, Button, Image, Layout, Menu, message, theme } from 'antd';
import logo from "../media/orioons_dark.png";
import TopBar from './topBar';
import { forceReloadInstanceAndResources, user } from '../helpers/firebase';
import pages from '../helpers/pages';
import route from '../helpers/route';
import Footer from './footer';




const { Header, Sider, Content } = Layout;
const Skeleton = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [activePage, setActivePage]  = useState('');
 useEffect(()=>{
  const url = window.location.pathname.split("/")
  const path  = url[1]
  setActivePage(pages[path])
 } )
  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed} style={{backgroundColor:"white"}}>
        <div />
    <Image preview={false} width={ window.innerHeight > window.innerWidth ? "100%" : "100%"} className='mb-6 md:mb-2 p-4' src={logo} draggable={false} />

        <Menu
          theme="light"
          mode="inline"
          onSelect={(e)=>{route(e.key)}}
          defaultSelectedKeys={['1']}
          items={[
            {
              key: 'belt',
              icon: <LayoutOutlined />,
              label: 'Belt',
              
            },
            {
              key: '2',
              icon: <AppstoreOutlined />,
              label: 'Resources',
              children: [
                {
                  key: 'g1',
                  label: 'Services',
                  type: 'group',
                  children: [
                    { key: 'resources', label: 'All Resources' },
                    { key: 'free', label: 'Free Resources',  },
                  ],
                },
                {
                  key: 'g2',
                  label: 'Learning',
                  type: 'group',
                  children: [
                    { key: 'learn', label: 'Training and Tutorials' },
                  ],
                },
              ],
            },
            {
              key: 'my',
              icon: <DatabaseOutlined />,
              label: 'My Resourses',
            },
            {
              key: 'clients',
              icon: <TeamOutlined />,
              label: 'Clients',
            },
            {
              key: 'support',
              icon: <BookOutlined />,
              label: 'Support',
            },
            {
              key: 'settings',
              icon: <SettingOutlined />,
              label: 'Settings',
            },
          ]}
        />
      </Sider>
      <Layout className='bg-white'>
        <Header
          style={{
            padding: 0,
            background: 'white',
            
          }}
        >
      <div style={{display:"flex"}}>    <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
         
        />  <input
        type="text"
        className="w-full h-16 "
        placeholder="Search ..."
      />
      <div style={{display:'flex'}}>
        <Button className='h-10 m-3 pb-2' onClick={()=>{
          message.loading("Reloading Instance and Resources");
          forceReloadInstanceAndResources()
        }}><ReloadOutlined /></Button>
       <a href='./profile' className='flex'>
       <div className='block pt-3'>
          <span className='text-sm font-bold hidden md:block'>{user.displayName}</span> 
          <span className='text-xs hidden md:block'>{user.email}</span> <br/>
        
        </div>
      <Avatar size={48} icon={<UserOutlined />} className='m-2' />
       </a>
      </div>
          </div>
        </Header>
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: window.innerHeight,
          }}
        >
          {activePage}
        </Content>
        <Footer/>
      </Layout>
    </Layout>
  );
};
export default Skeleton;