import { Image, Checkbox} from "antd";
import logo from "../media/orioons.png";

const TopBar  = () => {

    return(
        <main className="">
  <div className="container ">
    <form className=" bg-[#343a40] py-4 px-10 w-screen shadow-lg">
    <Image preview={false} width={ window.innerHeight > window.innerWidth ? "5%" : "5%"} className='mb-6 md:mb-2' src={logo} draggable={false} />
      <div className="mb-4 flex items-center">

        <input
          type="text"
          className="w-full  border border-gray-400 p-2"
          placeholder="Search and press enter..."
        />
        <button className="ml-2 bg-[#343a40] p-2 text-white hover:bg-white hover:text-[#343a40] transition duration-300 ease-in-out ">
          Search
        </button>
      </div>
      <div className="flex">
  
      </div>
    </form>
   
  </div>
</main>

    )
}

export default TopBar