import React from 'react';
import { Card } from 'antd';
const gridStyle = {
  width: '25%',
  textAlign: 'center',
};
const TopServices = () => (
  <Card title="Orioons Services ">
    <Card.Grid style={gridStyle}>Virtual Machine</Card.Grid>
    {/* <Card.Grid style={gridStyle}>Orioon Notation Database</Card.Grid> */}
    {/* <Card.Grid style={gridStyle}>Communications</Card.Grid> */}
    {/* <Card.Grid style={gridStyle}>Storage</Card.Grid> */}
    <Card.Grid style={gridStyle}>App Services</Card.Grid>
    <Card.Grid style={gridStyle}>Web Services</Card.Grid>
    {/* <Card.Grid style={gridStyle}>Security</Card.Grid> */}
    {/* <Card.Grid style={gridStyle}>AI and Machine Learning</Card.Grid> */}
  </Card>
);
export default TopServices;