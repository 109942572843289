import axios from "axios";

const dashboardURI = "https://api.user.orioons.com/api/v2/"


 // Add instance with proper headers
export const addInstance = (instanceData) => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: `${dashboardURI}instances`,
      timeout: 10000, // Optional timeout setting
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': "https://dashboard.orioons.com", // Change "https://dashboard.orioons.com" to specific origin if needed
      },
    });

    instance.post('', { data: instanceData })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response ? error.response.data : error.message);
      });
  });
};

// Fetch instance with proper headers
export const fetchInstance = (uid) => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: `${dashboardURI}instances/${uid}`,
      timeout: 10000, // Optional timeout setting
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': "https://dashboard.orioons.com", // Change "https://dashboard.orioons.com" to specific origin if needed
      },
    });

    instance.get()
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response ? error.response.data : error.message);
      });
  });
};

// Fetch resources with proper headers
export const fetchResources = () => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: `${dashboardURI}resources/`,
      timeout: 10000, // Optional timeout setting
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': "https://dashboard.orioons.com", // Change "https://dashboard.orioons.com" to specific origin if needed
      },
    });

    instance.get()
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response ? error.response.data : error.message);
      });
  });
};