import React from 'react';
import { Button, Col, Divider, Row, Space, Statistic, Table, Tag } from 'antd';
import TopCard from '../components/topCard';
import TopServices from '../components/topServices';
import FeaturedServices from '../components/featuredServices';

const Dashboard = () =>{
    return(
        <>
        <TopCard/>
        <Divider></Divider>
        <TopServices/>
        <Divider></Divider>
        <FeaturedServices/>
        
        </>
        )
}
export default Dashboard;