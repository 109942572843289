import { root } from "..";
import Skeleton from "../components/layout";
import LoginPage from "../pages/login";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from "react";
const route = (path, condition)  => {
    window.history.pushState(null, "", path);
    switch(path){
        case "" :
            root.render(<React.StrictMode><div className="App"> <header className="App-header-light">  <Spin indicator={<LoadingOutlined style={{fontSize: 24, color:"#343a40"}} spin/> } />  </header> </div></React.StrictMode>);
            setTimeout(()=>{
                route("belt")
            }, 2000)
        break;
        case "login" :
            root.render(<LoginPage/>)
        break;
        case "belt" :
            root.render(<Skeleton/>)
        break;
        case "resources" :
            root.render(<Skeleton/>)
        break;
        case "my" :
            root.render(<Skeleton/>)
        break;
        case "free" :
            root.render(<Skeleton/>)
        break;
        case "learn" :
            root.render(<Skeleton/>)
        break;
        case "clients" :
            root.render(<Skeleton/>)
        break;
        case "support" :
            root.render(<Skeleton/>)
        break;
        case "settings" :
            root.render(<Skeleton/>)
        break;
       default :
            root.render(<><div className="relative flex items-top justify-center min-h-screen bg-white  sm:items-center sm:pt-0">
            <div className="max-w-xl mx-auto sm:px-6 lg:px-8">
              <div className="flex items-center pt-0 sm:justify-start sm:pt-0">
                <div className="px-4 text-lg text-gray-500 border-r border-gray-400 tracking-wider">
                  404
                </div>
                <div className="ml-4 text-lg text-gray-500 border-r border-gray-400 pr-4 uppercase tracking-wider">
                  Not Found
                </div>
                <div className="ml-4 text-lg text-gray-500 border-b border-gray-400 uppercase tracking-wider">
                  <a href="./belt">Return Home</a>
                </div>
              </div>
            </div>
          </div>
          </>)
        break;
    }


}

export default route