const FeaturedServices = () => {

    return(
        <section className="max-w-full mx-auto mb-12">
  <article>
    <h2 className="text-2xl font-extrabold text-gray-900">Featured</h2>
    <section className="mt-6 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-x-6 gap-y-8">
      <article
        className="relative w-full h-64 bg-cover bg-center group rounded-lg overflow-hidden shadow-lg hover:shadow-2xl  transition duration-300 ease-in-out"
        style={{
          backgroundImage:
            'url("https://images.unsplash.com/photo-1623479322729-28b25c16b011?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1740&q=80")'
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50 group-hover:opacity-75 transition duration-300 ease-in-out" />
        <div className="relative w-full h-full px-4 sm:px-6 lg:px-4 flex justify-center items-center">
          <h3 className="text-center">
            <a className="text-white text-2xl font-bold text-center" href="#">
              <span className="absolute inset-0" />
            IaaS - Infrastructure as a Service
            </a>
          </h3>
        </div>
      </article>
      <article
        className="relative w-full h-64 bg-cover bg-center group rounded-lg overflow-hidden shadow-lg hover:shadow-2xl  transition duration-300 ease-in-out"
        style={{
          backgroundImage:
            'url("https://images.unsplash.com/photo-1569012871812-f38ee64cd54c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80")'
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50 group-hover:opacity-75 transition duration-300 ease-in-out" />
        <div className="relative w-full h-full px-4 sm:px-6 lg:px-4 flex justify-center items-center">
          <h3 className="text-center">
            <a className="text-white text-2xl font-bold text-center" href="#">
              <span className="absolute inset-0" />
             PaaS - Platform as a Service
            </a>
          </h3>
        </div>
      </article>
      <article
        className="relative w-full h-64 bg-cover bg-center group rounded-lg overflow-hidden shadow-lg hover:shadow-2xl  transition duration-300 ease-in-out"
        style={{
          backgroundImage:
            'url("https://images.unsplash.com/photo-1511376777868-611b54f68947?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1170&q=80")'
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50 group-hover:opacity-75 transition duration-300 ease-in-out" />
        <div className="relative w-full h-full px-4 sm:px-6 lg:px-4 flex justify-center items-center">
          <h3 className="text-center">
            <a className="text-white text-2xl font-bold text-center" href="#">
              <span className="absolute inset-0" />
             SaaS - Software as a Service
            </a>
          </h3>
        </div>
      </article>
    </section>
  </article>
</section>

    )
}

export default FeaturedServices