import React, { useEffect, useState } from 'react';
import { LikeOutlined, MessageOutlined, StarOutlined } from '@ant-design/icons';
import { Avatar, Col, Divider, List, QRCode, Row, Space, Spin, Statistic } from 'antd';
import logoSM from "../media/logoSM.png";
import { exportInstance, instanceData } from '../helpers/firebase';
import { LoadingOutlined } from '@ant-design/icons';

const data = Array.from({
  length: 1,
}).map((_, i) => ({
  href: 'https://orioons.cloud/manage',
  title: `ant design part ${i}`,  
  content:
    'Scan the QR code to add this instance to your Orioons Main',
}));
const IconText = ({ icon, text }) => (
  <Space>
    {React.createElement(icon)}
    {text}
  </Space>
);
const TopCard = () => {
  const [dom, setDOM] = useState(<React.StrictMode><div className="App"> <header className="App-header-light-small">  <Spin indicator={<LoadingOutlined style={{fontSize: 24, color:"#343a40"}} spin/> } /> </header> </div></React.StrictMode>)
  const [instance, setInstance] = useState(instanceData)

  useEffect(e=>{
    exportInstance().then((e)=>{
      setInstance(e)
      setDOM(<List
        className='bg-white  rounded-lg '
        itemLayout="vertical"
        size="small"
        
        dataSource={data}
        
        renderItem={(item) => (
          <List.Item
          key={item.title}
         
          >
            <List.Item.Meta
              title={<div className='block'><h2 className='text-2xl font-bold mx-2'>Assigned Belt</h2><p className='text-gray-500 mx-2'>{instance.host} - {instance.ip}</p></div>}
              description={<>
           <div className="">
  <div className="max-w-full  bg-white flex flex-col rounded overflow-hidden shadow-lg">
   
    <div className="mt-2 flex justify-start bg-white p-2">
      <div className="flex mx-2 ml-6 h8 px-2 flex-row items-baseline rounded-full bg-gray-100 p-1">
      
        <p className="font-normal text-sm ml-1 text-gray-500">Main Instance</p>
      </div>
    </div>
    <div className="mt-2 flex sm:flex-row mx-6 sm:justify-between flex-wrap ">
      <div className="flex flex-row place-items-center p-2">
        
        <div className="flex flex-col ml-2">
          <p className="text-xs text-gray-500 font-bold">Orioons Basic</p>
          <p className="text-xs text-gray-500">vCore: {instance.core}</p>
          <div className="text-xs text-gray-500">Memory: {instance.memory} GB</div>
          <div className="text-xs text-gray-500">Storage: {instance.storage} GB</div>
        </div>
      </div>
      <div className="flex flex-col p-2">
        <p className="text-gray-500">
          <span className="font-bold">Orioons Belt</span> {instance.approval}
        </p>
        <p className="text-gray-500">{instance.location}</p>
        <p className="text-gray-500">Quota: {instance.limit} Services</p>
      </div>
      <div className="flex flex-col flex-wrap p-2">
      
      </div>
    </div>
    <div className="mt-4 bg-gray-100 flex flex-row flex-wrap md:flex-nowrap justify-between items-baseline">
      <div className="flex mx-6 py-4 flex-row flex-wrap">
      
        <div className="text-sm mx-2 flex flex-col">
        <QRCode
      size={128}
            errorLevel="H"
            value="https://ant.design/"
            icon={logoSM}
            className='mb-1' />
            {item.content}
        </div>
       
      </div>
      <div className="md:border-l-2 mx-6 md:border-dotted flex flex-row py-4 mr-6 flex-wrap">
       
        <div className="text-sm mx-2 flex flex-col">
          <p>{instance.plan} Plan</p>
          <p className="font-bold">${instance.pricing}</p>
          <p className="text-xs text-gray-500">Price / <span className='font-bold'>Star System</span></p>
        </div>
        
      </div>
    </div>
  </div>
</div></>}
            />
            
            
          </List.Item>
        )}
        />)
    }).catch((e)=>{
      console.log(e)
    })
  })
  return(

    <>
    {dom}
    </>
    
  )
  };
  export default TopCard;